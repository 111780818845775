<template>
  <div class="item-mobile">
    <!--  teams  -->
    <div class="item-mobile__teams">
      <div class="item-mobile__teams__team">
        <div
          class="item-mobile__teams__team__side-button"
          :class="{ 'item-mobile__teams__team__side-button--hidden': game.isFirst }"
        >
          <CarouselButton
            is-mobile
            @click="
              $emit('click', $event);
              onClickLeft();
            "
          />
        </div>
        <CarouselTeamInfo :is-profile-button-visible="false" :team="game.homeTeam" />
      </div>
      <div class="item-mobile__teams__team item-mobile__teams__team-away">
        <CarouselTeamInfo :isHomeTeam="false" :is-profile-button-visible="false" :team="game.awayTeam" />
        <div
          class="item-mobile__teams__team__side-button"
          :class="{ 'item-mobile__teams__team__side-button--hidden': game.isLast }"
        >
          <CarouselButton
            type="right"
            @click="
              $emit('click', $event);
              onClickRight();
            "
          />
        </div>
      </div>
    </div>
    <!--  date time  -->
    <div class="item-mobile__date-time">
      <CarouselDateTime :game-date-time="game.date_time_utc" />
    </div>
    <!--  buttons  -->
    <div v-if="game.hasStatistics" class="item-mobile__buttons">
      <div class="item-mobile__buttons__home">
        <ProfileButton is-mobile @click="onClickProfile('homeTeam')" />
      </div>
      <StatisticsButton
        @click="
          $emit('show-statistics');
          onClickSeeStats();
        "
      />
      <div class="item-mobile__buttons__away">
        <ProfileButton is-mobile @click="onClickProfile('awayTeam')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  RightBetCarrouselViewProfile,
  RightBetCarrouselNext,
  RightBetCarrouselBefore,
  RightBetCarrouselViewStats,
} from '@/utils/analytics';
export default {
  name: 'CarouselItemMobile',
  props: {
    game: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CarouselButton: () => import('@/components/Sections/GolTipster-v2/General/CarouselButton'),
    CarouselTeamInfo: () => import('@/components/Sections/GolTipster-v2/General/CarouselTeamInfo'),
    CarouselDateTime: () => import('@/components/Sections/GolTipster-v2/General/CarouselGameDateTime'),
    ProfileButton: () => import('@/components/Sections/GolTipster-v2/General/ProfileButton'),
    StatisticsButton: () => import('@/components/Sections/GolTipster-v2/General/GoToStatisticsButton'),
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  methods: {
    onClickRight() {
      RightBetCarrouselNext({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
    },
    onClickLeft() {
      RightBetCarrouselBefore({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
    },
    onClickProfile(teamType) {
      RightBetCarrouselViewProfile({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        team_id: this.game[teamType].team_id,
        season_id: this.game.seasonId,
        game_id: this.game.game_id,
      });
      if (this.$route.name === 'viewAll') {
        this.$router.push({
          name: 'teamProfile',
          query: { team: this.game[teamType].team_id, season: this.game.seasonId, isLanding: true },
        });
      } else {
        let routeData = this.$router.resolve({
          name: 'teamprofile',
          query: { team: this.game[teamType].team_id, season: this.game.seasonId },
        });
        window.open(routeData.href, '_blank');
      }
    },
    onClickSeeStats() {
      RightBetCarrouselViewStats({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        season_id: this.game.seasonId,
        game_id: this.game.game_id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.item-mobile {
  width: 100%;
  min-height: 11.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0.2rem;
  background-image: url('/images/base-mobile.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 12px;

  @media screen and (max-width: 530px) {
    min-height: 9.75rem;
  }

  @media screen and (max-width: 460px) {
    min-height: 9.25rem;
  }

  &__teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__team {
      display: flex;
      align-items: center;

      &-away {
        justify-self: right;
      }

      &__side-button {
        margin: 0 0.4rem;

        &--hidden {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  &__date-time {
    margin-top: 0.2rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.75rem;

    &__home {
      margin-left: 10%;
    }

    &__away {
      margin-right: 10%;
    }

    @media screen and (max-width: 480px) {
      &__home {
        margin: 0 0 0 0.5rem;
      }

      &__away {
        margin: 0 0.5rem 0 0;
      }
    }
  }

  & div.game-date-time {
    @media screen and (max-width: 460px) {
      font-size: 0.625rem;
    }
  }
}
</style>
